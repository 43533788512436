import "@notus-pro/react/tailwind.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/globals.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import type { AppProps } from 'next/app'
import { UserProvider } from '@auth0/nextjs-auth0';
import AppWrapper from "../context/AppContext"
import { useEffect, useState } from "react";
import Router from "next/router";
import LoadingPage from "../components/LandingPages/Loading";
import { Slide, ToastContainer } from "react-toastify";

export default function MyApp({ Component, pageProps }: AppProps) {
  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    const start = () => {
      setLoading(true);
    }
    const end = () => {
      setLoading(false);
    }
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    }
  },[]);

  return (
        <AppWrapper>
            <UserProvider>
            <ToastContainer autoClose={2500} transition={Slide} position={"bottom-right"}/>
              {loading ? (LoadingPage()) : 
                (<Component {...pageProps}/>)}
            </UserProvider>
        </AppWrapper>
  );
}
