import Link from 'next/link';
import Image from 'next/image';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { useAppContext } from '../../context/AppContext';
import router from 'next/router';


export default function Sidebar({brand,items,activeColor,orgs}:{brand:any,items:any,activeColor:any,orgs:any}){
    const value = useAppContext();

    var activeColors : any = {
        red: "text-red-500 hover:text-red-700",
        orange: "text-orange-500 hover:text-orange-700",
        amber: "text-amber-500 hover:text-amber-700",
        emerald: "text-emerald-500 hover:text-emerald-700",
        teal: "text-teal-500 hover:text-teal-700",
        lightBlue: "text-lightBlue-500 hover:text-lightBlue-700",
        indigo: "text-indigo-500 hover:text-indigo-700",
        purple: "text-purple-500 hover:text-purple-700",
        pink: "text-pink-500 hover:text-pink-700"
    };

    let menuItems = [
        {
            menu: 'New Organization',
            state: true,
            id: -9
        },
    ]

    const setMenuItems = () => {
        if(orgs) {
            orgs.forEach((o:any)=>{
                let item = {
                    menu: o.name,
                    state: false,
                    id: o.id
                }
                menuItems.splice(0,0,item);
            })
        }
    }

    setMenuItems()

    const handleOrganizationClick = async (e:any,id:any) => {
        if(id >= 0){
            let response = await fetch('../api/cookies/'+id,{
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            })
            if(response.status === 200){
                router.push(router.asPath,router.asPath,{shallow: false});
            }else{

            }  
        }else{
            router.push('/create/organization');
        }
    }

    return (
        <> 
            <nav className={"block py-2 top-0 bottom-0 border-r border-blueGray-500 bg-blueGray-800 left-0 fixed flex-row flex-nowrap z-50 ease-in-out transform sidebar -translate-x-full"}>
                <div className="flex-col min-h-full px-0 flex flex-wrap items-center w-full mx-auto overflow-y-auto overflow-x-hidden">
                    <div className="opacity-100 relative mt-4 overflow-y-auto overflow-x-hidden z-40 flex-1 rounded w-full">
                        <div className="flex flex-col relative items-center">
                            <Link href={brand.to}>
                                <div className={'cursor-pointer brand-image'}>
                                    <Image className='flex' src={brand.image} layout={'fill'}/>
                                </div>
                            </Link>
                            <span className="brand-text mt-1 uppercase font-bold text-white text-base pb-4">Policy Guard</span>
                        </div>
                        
                        {
                            items.map((i:any,index:any)=>{
                                return(
                                    <div key={"sidebar-item-"+index} >
                                        <div className="flex sidebar-large">
                                            <div className={i.active ? "w-full mr-4" : ' w-full'}>
                                                {i.divider ? <p className="brand-text my-8 min-w-full"></p>
                                                : i.title ? 
                                                <h6 className="brand-text min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                                    {i.title}
                                                </h6>
                                                : i.org ?
                                                <>
                                                <div className="w-full text-right top-16">
                                                    <Menu as="div" className="relative text-left z-9999">
                                                        <div>
                                                            <Menu.Button className="flex justify-between justify-center w-full py-2 border-0 bg-blueGray-800 text-blueGray-700 rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <div className="flex justify-between items-center">
                                                            <div className="cursor-pointer sidebar-items-grid sidebar-icons-div">
                                                                <i className={i.icon + " text-lg ml-6 mb-4"}></i>
                                                                <span className="brand-text w-full sidebar-icons mb-3">{i.text}</span>
                                                            </div>
                                                            <div className='sidebar-icons-div'>
                                                                <i className='fas fa-chevron-down mr-4 brand-text '></i> 
                                                            </div>
                                                        </div>
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                        >
                                                        <Menu.Items className="relative right-0 mt-2 ml-8 origin-top-left bg-blueGray-700 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sidebar-menu">
                                                            <div className="">
                                                            {
                                                                menuItems.map((item:any,index:number)=>{
                                                                    return (
                                                                        <div key={"menu-item"+index} className="">
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                <button
                                                                                    className={`${
                                                                                    active ? 'text-blueGray-500 sidebar-active-bg' : 'text-blueGray-500'
                                                                                    }   
                                                                                    ${(index < 1) ? " menu-rounded-border-top " : (index === menuItems.length-1) ? "menu-rounded-border-bottom" : "" } 
                                                                                    group flex text-start w-full py-2 text-sm`}
                                                                                    onClick={(e:any)=>{handleOrganizationClick(e,item.id)}}
                                                                                >
                                                                                    <div className='flex justify-between'>
                                                                                        <div>
                                                                                            {(menuItems[index].state) ? <i className="fas fa-plus-square text-lightBlue-500 font-bold ml-4 mr-2"></i> : <></>}
                                                                                        </div>
                                                                                        <span className={(menuItems[index].state) ? 'flex w-64 text-start' : 'flex w-64 text-start ml-4'}>{item.menu}</span>
                                                                                        
                                                                                    </div>
                                                                                </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            </div>
                                                        </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                                </>
                                                :
                                                <Link href={i.link.href} >
                                                    <div className="w-64 cursor-pointer ">
                                                        <div className={i.active ? "rounded sidebar-active-bg" : "" }>
                                                            <div className={i.active ? "sidebar-icons-div sidebar-items-grid" : " cursor-pointer sidebar-items-grid sidebar-icons-div " }>
                                                                <i className={i.active ? i.icon + "  ml-8 text-lg opacity-100 w-8 mb-4 text-center align-middle " + activeColors[activeColor] : i.icon + " text-center align-middle w-8 text-lg ml-8 mb-4"}></i>
                                                                {i.active ? <span className={"brand-text w-full sidebar-icons opacity-100 mb-3 " + activeColors[activeColor] }>{i.text}</span> : <span className="brand-text w-full sidebar-icons mb-3">{i.text}</span> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                }
                                            </div> 
                                        </div>
                                        <div className={"sidebar-mid justify-center mt-2 px-2"}>
                                            <div className={i.active ? "border-b-2 border-lightBlue-500" : ''}>
                                                {!i.divider && !i.title ?  
                                                    <Link href={i.link.href} >
                                                        <div className="cursor-pointer sidebar-icons-div text-xl block ">
                                                            <i className={i.active ? i.icon + " mb-1 sidebar-icons text-white" : i.icon + " sidebar-icons mb-1 "}></i>
                                                        </div>
                                                    </Link> : <></>
                                                }
                                            </div>
                                        </div> 
                                        
                                    </div>
                                )
                            })
                        }
        
                    </div>
                </div>
            </nav>
        </>
    )
}