import Sidebar from "../../notus/Sidebar";
import { useUser } from "@auth0/nextjs-auth0";
import NavbarSearchUser from "../../notus/NavbarSearchUser";
import { ThemeColor } from "../../ThemeColor";
import { useEffect, useState } from "react";
import useSWR from "swr";

let sidebar : any = {
  security: false,
  alerts: false,
  sites: false,
  organization: false,
  account: false,
  docs: false,
  faq: false
}

const fetcher = (url:string) => fetch(url).then((res) => res.json());

export default function AppLayout({ children, title, sidebarClicked}: { children:any, title: string, sidebarClicked:any}) {
    const { isLoading, user } = useUser();
    const [currentOrgList,setCurrentOrgList] = useState([{}]); 
    const [currentOrgName,setCurrentOrgName] = useState('Organization');

    let orgsdata = useSWR("/api/user/allOrgs/names",fetcher);

    useEffect(()=>{
      if(orgsdata.data){
        let orgList = [orgsdata.data.orgData];
        let orgID = orgsdata.data.orgID;
        orgList.forEach((o:any)=>{
          let orgName = o.filter((org:any)=>(org.id === Number(orgID)))
          setCurrentOrgList(o)
          if(orgName[0]) setCurrentOrgName(orgName[0].name);
        })
        
      }
    },[orgsdata.data])

    sidebar = (sidebarClicked) ? sidebarClicked : sidebar;

    const brand = {
        text: "PolicyGuard",
        image: "/logo_transparent.png",
        to: "/",
      };
    
    const activeColor = ThemeColor.LightBlue;

    const itemsWithOrg = [
        {
          icon: "fas fa-tv",
          text: "Reports",
          active: sidebar.security,
          link: { href: "/app"},
        },
        {
          icon: "fas fa-bell",
          text: "Alerts",
          active: sidebar.alerts,
          link: { href: "/app/alerts" },
        },
        {
          icon: "fas fa-table",
          text: "Sites",
          active: sidebar.sites,
          link: { href: "/app/sites/"},
        },
        {
          icon: "fas fa-tools",
          text: "Settings",
          active: sidebar.organization,
          link: { href: "/app/organization" },
        },
        { divider: true },
        {
          icon: "fas fa-laptop-code",
          text: "Documentation",
          active: sidebar.docs,
          link: { href: "/app/docs" },
        },
        {
          icon: "fas fa-clipboard-list",
          text: "FAQ",
          active: sidebar.faq,
          link: { href: "/app/faq" },
        },
      ];

    return (
        <div className="bg-lightBlue-800">
            <Sidebar brand={brand} activeColor={activeColor} items={itemsWithOrg} orgs={currentOrgList}/>         
            <div className="relative overflow-x-hidden py-4 px-2 sidebar-margins bg-blueGray-200 pt-20 pl-6">
              <NavbarSearchUser title={title} userName={user?.nickname as string} image={user?.picture as string} active={sidebar} orgs={currentOrgList} orgName={currentOrgName}/>
              {children}
            </div> 
        </div>
    )
  }


