 import propTypes from "prop-types";
import Link from 'next/link';
import { Fragment, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { useRouter } from "next/router";
import Image from "next/image";

export default function NavbarSearchUser({title, image, active, orgs, orgName}: {title:string, userName:string, image:string, active:any, orgs:any, orgName:string}) {
  const [openOrgMenu,setOpenOrgMenu] = useState(false);
  const router = useRouter();

  let menuItems = [
    {
        menu: 'New Organization',
        state: true,
        id: -9,
        icon: "fas fa-plus-square"
    },
  ]

  const dropdownItems = [
    {
        menu: "Profile",
        state: false,
        href: '/app/account'
    },
    {
        menu: "Sign Out",
        state: false,
        href: '/api/auth/logout'
    }
  ]

  const sidebarMenuItems = [
    {
      menu: "Reports",
      state: true,
      icon: "fas fa-tv",
      href: '/app'
    },
    {
      menu: "Alerts",
      state: true,
      icon: "fas fa-bell",
      href: '/app/alerts'
    },
    {
      menu: "Sites",
      state: true,
      icon: "fas fa-table",
      href: '/app/sites'
    },
    {
      menu: "Settings",
      state: true,
      icon: "fas fa-tools",
      href: '/app/organization'
    },
    {
      menu: "Documentation",
      state: true,
      icon: "fas fa-laptop-code",
      href: '/app/docs'
    },
    {
      menu: "FAQ",
      state: true,
      icon: "fas fa-clipboard-list",
      href: '/app/faq'
    },
  ]

  const setMenuItems = () => {
      if(orgs) {
          orgs.forEach((o:any)=>{
              let item = {
                  menu: o.name,
                  state: false,
                  id: o.id,
                  icon: ""
              }
              menuItems.splice(0,0,item);
          })
      }
  }
  
  setMenuItems();


  const handleOrganizationClick = async (e:any,id:any) => {
    if(id >= 0){
        let response = await fetch('../api/cookies/'+id,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        if(response.status === 200){
            router.push(router.asPath,router.asPath,{shallow: false});
        }else{

        }  
    }else{
        router.push('/create/organization');
    }
}

  return (
    <>
      <nav className="navbar-small absolute top-0 left-0 w-full z-10 bg-blueGray-800 lg:flex-row lg:flex-nowrap justify-between flex items-center py-1 px-4">
        <></>
        <div className="flex w-full justify-end">
          <div className="text-right z-50 w-full px-2">
            <Menu as="div" className="relative text-left margins-nav-menu-small">
              {({open}) => 
              <>
                <div className="mt-6 flex justify-between">
                  <div className="flex items-center">
                      <Link href={'/'}>
                          <div className='cursor-pointer brand-image w-12 h-12'>
                              <Image className='flex' src={"/logo_transparent.png"} layout={'responsive'} width={48} height={48}/>
                          </div>
                      </Link>
                      <span className="uppercase text-white font-bold ml-2">Policy Guard</span>
                  </div>
                  <div className="">
                  <Menu.Button className="grid cursor-pointer text-blueGray-400 focus:outline-none outline-none active:outline-node ">
                      <div className="items-center cursor-pointer flex border border-lightBlue-500 nav-menu-bg rounded-md px-2 py-1">
                        <span className="w-10 h-10 text-sm text-white bg-blueGray-300 inline-flex items-center justify-center rounded-full mr-2">
                          <img className="w-full rounded-full align-middle border-none" src={image}></img>
                        </span>
                        {orgName !== '' ? <span className="text-lg nav-menu-text">
                          {orgName}
                        </span> : <span className="text-lg nav-menu-text mr-2">------</span>}
                      </div>
                    </Menu.Button>
                  </div>
                </div>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                  
                <Menu.Items className="relative flex z-50 right-0 mt-2 margins-nav-menu-items-small nav-menu-small-grid-wrapper origin-top-right bg-blueGray-800 ml-64 divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                    <div className="border-r border-blueGray-500">
                      {
                          sidebarMenuItems.map((item:any,index:number)=>{
                              return (
                                  <div key={"orgmenu-item"+index} className="">
                                      <Menu.Item>
                                          {({ active }) => (
                                          <button
                                              className={`${
                                              active ? 'text-white sidebar-active-bg' : 'text-white'
                                              }    
                                              group flex text-start py-2 nav-menu-items text-sm`}
                                              onClick={(e:any)=>{router.push(sidebarMenuItems[index].href)}}
                                          >
                                              <div className='flex justify-between'>
                                                  <div>
                                                      {(sidebarMenuItems[index].state) ? <i className={sidebarMenuItems[index].icon + " text-lightBlue-500 font-bold ml-4 mr-2"}></i> : <></>}
                                                  </div>
                                                  <span className={(sidebarMenuItems[index].state) ? 'flex w-64 text-start' : 'flex w-64 text-start ml-4'}>{item.menu}</span>
                                                  
                                              </div>
                                          </button>
                                          )}
                                      </Menu.Item>
                                  </div>
                              )
                          })
                      }
                    </div>
                    <div className="">
                      {
                          menuItems.map((item:any,index:number)=>{
                              return (
                                  <div key={"orgmenu-item"+index} className="">
                                      <Menu.Item disabled={(menuItems.length > 5 && index === menuItems.length-1)}>
                                          {({ active }) => (
                                          <button
                                              className={`${
                                              active ? 'text-white sidebar-active-bg' : 'text-white'
                                              }    
                                              group flex text-start py-2 nav-menu-items text-sm`}
                                              onClick={(e:any)=>{handleOrganizationClick(e,item.id)}}
                                          >
                                              <div className='flex justify-between'>
                                                  <div>
                                                      {(menuItems[index].state) ? <i className={(menuItems.length > 5 && index === menuItems.length-1) ? menuItems[index].icon + " text-blueGray-500 font-bold ml-4 mr-2" : menuItems[index].icon + " text-lightBlue-500 font-bold ml-4 mr-2"}></i> : <></>}
                                                  </div>
                                                  <span className={`${(menuItems[index].state) ? 'flex w-64 text-start' : 'flex w-64 text-start ml-4'} ${(menuItems.length > 5 && index === menuItems.length-1) ? "text-blueGray-500" : "text-white"}` }>{item.menu}</span>
                                              </div>
                                          </button>
                                          )}
                                      </Menu.Item>
                                  </div>
                              )
                          })
                      }
                    </div>
                    <div className="">
                    {
                        dropdownItems.map((item:any,index:number)=>{
                            return (
                                <div key={"dropdown-item"+index} className="">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'text-white sidebar-active-bg' : 'text-white'
                                            }    
                                            group flex text-start w-full py-2 text-sm`}
                                            onClick={(e:any)=>{
                                              if(item.href) router.push(item.href)
                                              else setOpenOrgMenu(!openOrgMenu)
                                            }}
                                        >
                                            <div className='flex justify-end'>
                                                <span className='flex  ml-4'>{item.menu}</span>
                                                
                                            </div>
                                        </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            )
                        })
                    }
                    </div>
                    
                </Menu.Items>
                </Transition>
            </>}   
            </Menu>
          </div>
        </div>
      </nav>
      <nav className="navbar-large absolute top-0 left-0 w-full z-10 bg-blueGray-800 py-1">
        <span className="text-white align-middle absolute lg:text-white text-sm uppercase inline-block font-semibold mt-5 ml-4">{title}</span>
        <div className="flex w-full justify-end">
          <div className="text-right z-50 w-full">
            <Menu as="div" className="relative text-left margins-nav-menu">
              {({open}) => 
              <>
                <div className="mt-6 flex justify-between">
                  <div></div>
                  <div className="mr-2">
                    <Menu.Button className="grid cursor-pointer text-blueGray-400 focus:outline-none outline-none active:outline-node ">
                    <div className="items-center cursor-pointer flex border border-lightBlue-500 nav-menu-bg rounded-md px-2 py-1">
                        <span className="w-10 h-10 text-sm text-white bg-blueGray-300 inline-flex items-center justify-center rounded-full mr-2">
                          <img className="w-full rounded-full align-middle border-none" src={image}></img>
                        </span>
                        {orgName !== '' ? <span className="text-lg nav-menu-text">
                          {orgName}
                        </span> : <span className="text-lg nav-menu-text mr-2">------</span>}
                      </div>
                    </Menu.Button>
                    </div>
                </div>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                  
                <Menu.Items className="relative flex z-50 right-0 mt-2 margins-nav-menu-items nav-menu-grid-wrapper origin-top-right bg-blueGray-800 ml-64 divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden sidebar-menu">
                    <div className="">
                      {
                          menuItems.map((item:any,index:number)=>{
                              return (
                                  <div key={"orgmenu-item"+index} className="">
                                      <Menu.Item disabled={(menuItems.length > 5 && index === menuItems.length-1)} >
                                          {({ active }) => (
                                          <button
                                              className={`${
                                              active ? 'text-white sidebar-active-bg' : 'text-white'
                                              }    
                                              group flex text-start py-2 nav-menu-items text-sm`}
                                              onClick={(e:any)=>{handleOrganizationClick(e,item.id)}}
                                          >
                                              <div className='flex justify-between'>
                                                  <div>
                                                      {(menuItems[index].state) ? <i className={(menuItems.length > 5 && index === menuItems.length-1) ? menuItems[index].icon + " text-blueGray-500 font-bold ml-4 mr-2" : menuItems[index].icon + " text-lightBlue-500 font-bold ml-4 mr-2"}></i> : <></>}
                                                  </div>
                                                  <span className={`${(menuItems[index].state) ? 'flex w-64 text-start' : 'flex w-64 text-start ml-4'} ${(menuItems.length > 5 && index === menuItems.length-1) ? "text-blueGray-500" : "text-white"}` }>{item.menu}</span>
                                                  
                                              </div>
                                          </button>
                                          )}
                                      </Menu.Item>
                                  </div>
                              )
                          })
                      }
                    </div>
                    <div className="">
                    {
                        dropdownItems.map((item:any,index:number)=>{
                            return (
                                <div key={"dropdown-item"+index} className="">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'text-white sidebar-active-bg' : 'text-white'
                                            }    
                                            group flex text-start w-full py-2 text-sm`}
                                            onClick={(e:any)=>{
                                              if(item.href) router.push(item.href)
                                              else setOpenOrgMenu(!openOrgMenu)
                                            }}
                                        >
                                            <div className='flex justify-end'>
                                                <span className='flex  ml-4'>{item.menu}</span>
                                                
                                            </div>
                                        </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            )
                        })
                    }
                    </div>
                    
                </Menu.Items>
                </Transition>
            </>}   
            </Menu>
          </div>
        </div>
      </nav>
      
    </>
  )
}

NavbarSearchUser.defaultProps = {
  brand: {},
  input: {},
  dropdown: {}
};
NavbarSearchUser.propTypes = {
  // properties to pass to the link object for the brand
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  brand: propTypes.object,
  // properties to pass to the Input component
  input: propTypes.object,
  // properties to pass to the DropdownUser component
  dropdown: propTypes.object
};
