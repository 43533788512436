import AppLayout from "../../components/layout/app/AppLayout";
import { TailSpin } from  'react-loader-spinner';

let sidebarClicked : any = {
    security: false,
    alerts: false,
    sites: false,
    organization: false,
    account: false
  }

export default function LoadingPage() {

    return(
        <AppLayout sidebarClicked={sidebarClicked} title={"Dashboard"} >
            <div className="loading-page-wrapper">
                <TailSpin color='#0ca5e9' visible={true} height={150} width={150}/>
            </div>
        </AppLayout>
    )
}