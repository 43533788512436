
import { createContext, useContext } from 'react';
import cookieCutter from 'cookie-cutter';

export type ContextType = {
    setOrgId: (orgId:number) => void,
    setWebId: (webId:number) => void,
    setPlanType: (planType:number) => void,
    deleteOrgId: () => void,
    deleteWebId: () => void,
    deletePlanType: () => void,
}

const AppContext = createContext<ContextType>({
    setOrgId: (orgId:number) => {},
    setWebId: (webId:number) => {},
    setPlanType: (planType:number) => {},
    deleteOrgId: () => {},
    deleteWebId: () => {},
    deletePlanType: () => {},
});

export default function AppWrapper({children} : {children: any}) {
    const setOrganization = (newOrgId: number) => {
        cookieCutter.set('currentOrg',newOrgId, {path: '/' })
    }

    const setWebsite = (newWebId:number) => {
        cookieCutter.set('currentSite',newWebId, {path: '/' })
    }

    const setPlan = (planType:number) => {
        cookieCutter.set('PG_PLAN',planType, {path: '/'})
    }

    const deleteOrganizationId = async () => {
        cookieCutter.set('currentOrg','',{path: '/', expires: new Date(0)})
    }

    const deleteWebsiteId = () => {
        cookieCutter.set('currentSite','',{path: '/', expires: new Date(0)})
    }

    const deletePlan = () => {
        cookieCutter.set('PG_PLAN','',{path: '/', expires: new Date(0)})
    }

    let value = {
        setOrgId: setOrganization,
        setWebId: setWebsite,
        setPlanType: setPlan,
        deleteOrgId: deleteOrganizationId,
        deleteWebId: deleteWebsiteId,
        deletePlanType: deletePlan,
    }

    

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );    
}

export function useAppContext() {
    return useContext(AppContext)
}
