export enum ThemeColor {
    BlueGray = "blueGray",
    Red = "red",
    Orange =  "orange",
    Amber = "amber",
    Emerald = "emerald",
    Teal = "teal",
    LightBlue = "lightBlue",
    Indigo =  "indigo",
    Purple = "purple",
    Pink = "pink",
    White = "white"
}

export interface IThemeColor {
   param: ThemeColor
}